<template>
  <div class="Footbar bg contact">
    <div class="w">
      <div class="content">
        <!-- <p>互联网药品信息服务资格证书编号：(京)-非经营性-2020-0034</p> -->
        <!-- <p>备案号：京ICP备17058294号-1</p> -->
        <p>公司：邢台天宇医药有限公司</p>
        <p>地址：邢台市信都区西环路66号综合办公楼-2</p>
        <p>电话：0319-2961112</p>
        <div class="content" style="display: flex; align-items: center;">
         
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >备案号：冀ICP备2024050157号-1</a
          >
          <div class="beian">
          <img style="width:20xp;  height: 22px;" src="../../assets/images/index/tubiao.png" alt="">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=13050302001981" rel="noreferrer" target="_blank">冀公网安备13050302001981</a>
        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footbar",
};
</script>

<style scoped>
.bg {
  color: #fff;
  background-image: linear-gradient(to right, #1eb8a0, #4998c1);
 
}

.contact {
  padding: 50px 0 30px 0;
  margin-top: 60px;
}

.company {
  margin-right: 50px;
}

.content p {
  margin-bottom: 10px;
}
.content a {
  color: #ffffff;
  margin-right: 10px;
}
.beian {
  display: flex;
  
  /* margin-top: 10px; */

}
.beian img {
  margin-right: 3px;
}

</style>